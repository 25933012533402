@import '../../assets/styles/mixins.scss';

.contactsWrap {
  @include BlueBG;

  .content {
    padding: 100px 0px 74px;
  }

  .childs {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.hiddenTitle {
  position: absolute;
  opacity: 0;
}

.bottomLogo {
  max-width: 403px;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.email {
  &::after {
    margin-left: 0;
    border-color: white;
  };
}

.minLinks {
  margin-top: 20px;
  a {
    width: 20px;
    height: 20px;
    margin-left: 0;
    margin-right: 15px;
  }
}

@include for-desktop-sm {
  .contactsWrap .content {
    padding: 80px 0px 70px;
  }
  .bottomLogo {
    max-width: 300px;
  }
  // .email {}
  // .file {}
}

@include for-tablet {
  .contactsWrap {
    .content {
      padding: 80px 0px 50px;
    }
    .childs, .right {
      flex-direction: column;
      // align-items: center;
    }
  }
  .bottomLogo {
    margin-bottom: 50px;
  }
  .email {
    // text-align: center;
    &:after {margin: 20px 0;}
  }
}

@include for-phone {
  .contactsWrap .content {
    padding: 50px 0px 40px;
  }
  .bottomLogo {
    max-width: 95%;
    margin-bottom: 26px;
  }
  .email {
    font-size: 22px;
    line-height: 27px;
    &:after {margin: 10px 0;}
  }
  .file {
    font-size: 16px;
    line-height: 19px;
  }
  .minLinks {
    margin-top: 20px;
    a {
      width: 20px;
      height: 20px;
      margin-left: 0;
      margin-right: 15px;
    }
  }
}