@import '../../assets/styles/mixins.scss';

.popupWrapper {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.popupOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0,0,0,.5);
  z-index: -1;
}

.popupContent {
  position: relative;
  width: 625px;
  margin-top: 50px;
  padding: 60px 50px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 10px;
}

.popupCloseCross {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: black;
  overflow: hidden;
  background-image: url(../../assets/images/close-cross.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.popupTitle {
  font-size: 30px;
  margin: 0 0 30px 0;
}

.fullWidth {
  margin-top: 50px;
  width: 100%;
}