@import '../../assets/styles/mixins.scss';

.copyWrap {
  @include BlueBG;
  
  .content {
    border-top: 2px solid white;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    padding: 20px 0;
  }

  .childs {
    display: flex;
    justify-content: space-between;
    font-size: 12px;

    @include for-phone {
      flex-direction: column;
    }
  }
}

