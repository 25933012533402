@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/colors.scss';

.textFieldWrap {
  position: relative;

  .title {
    text-align: left;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin-bottom: 10px;
  }

  .placeholder {
    position: absolute;
    bottom: calc(100% - 7px);
    background-color: white;
    left: 15px;
    font-size: 10px;
    line-height: 10px;
    padding: 1px 3px;
    text-align: left;
  }

  &.error {
  
    input,
    textarea {
      border-color: red;

      &:focus~.errorText {
        display: none;
      }
    }
  
    .errorText {
      position: absolute;
      color: $text-error;
      bottom: -6px;
      left: 15px;
      font-size: 11px;
      line-height: 10px;
      padding: 1px 3px;
      z-index: 1;
      background: white;
      
      // &::after {
      //   content: '';
      //   position: absolute;
      //   width: 100%;
      //   height: 100%;
      //   top: 50%;
      //   left: 0;
      //   margin-top: -1px;
      //   background: white;
      //   z-index: -1;
      // }
    }
  }
}

