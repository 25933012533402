// author: Victor K.
@import './colors.scss';
@import './theme.scss';

@mixin MainBlocksStyle {
  max-width: 1200px;
  padding: 140px 0;
  margin: auto;

  @include for-desktop-sm {
    padding: 100px 0;
  }

  @include for-phone {
    padding: 60px 0;
  }
}

@mixin Separator {
  &:after {
    display: block;
    width: 50px;
    content: '';
    margin: 15px 0;
    border-bottom: 2px solid $elem-purple;
  }
}

@mixin SeparatorRight {
  &:after {
    display: block;
    width: 50px;
    content: '';
    margin: 15px 0 15px auto;
    border-bottom: 2px solid $elem-purple;
  }
}

@mixin mainBackground() {
  // @media screen and (min-width: 1200px) {}
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position-x: center;
  background-position-y: center;
  @content;
}

@mixin BlockSelection {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

@mixin UnblockSelection {
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text;   /* Chrome/Safari/Opera */
  -khtml-user-select: text;    /* Konqueror */
  -moz-user-select: text;      /* Firefox */
  -ms-user-select: text;       /* Internet Explorer/Edge */
  user-select: text;           /* Non-prefixed version, currently
                                  not supported by any browser */
}

@mixin GeneralInput {
  display: block;
  width: 100%;
  box-sizing: border-box;
  font-family: inherit;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 20px;
  border-radius: 5px;
  padding: 16px 19px;
  border: 1px solid $bg-input;
  text-align: left;
  background-color: $bg-input;
  color: $text-dgrey;

  &:hover {
    background-color: $bg-input-hover;
  }

  &:focus {
    background-color: $bg-input-hover;
    color: $text-black;
  }
  @content;
}

@mixin for-desktop  {
  @media screen and (min-width: 1001px) {
    @content;
  }
}

@mixin for-desktop-sm  {
  @media screen and (max-width: 1000px) {
    @content;
  }
}

@mixin for-tablet  {
  @media screen and (max-width: 720px) {
    @content;
  }
}

@mixin for-phone {
  @media screen and (max-width: 480px) {
    @content;
  }
}