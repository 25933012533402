// author: Victor K.
@import '../../assets/styles/mixins.scss';

.siteLogoWrap {
  display: block;
  width: 100%;
  max-width: 530px;
  height: auto;
  pointer-events: none;
  object-fit: cover;
  position: relative;
}

.invert {
  .logo path {
    fill: $elem-white;
  }
}
.logoMobile {display: none;}

.logo {
  width: 100%;
  height: auto;
}

@include for-phone {
  .logoDesk {display: none;}
  .logoMobile {display: block;}
}