// author: Victor K.
@import './colors.scss';

@mixin DefaultBG {
  background-color: $bg-main-white;
  color: $text-black;
}

@mixin DefaultHover {
  &:hover {
    background-color: $bg-main-black;
    color: $text-white;
  }
}

@mixin BlackBG {
  background-color: $bg-main-black;
  color: $text-white;
}

@mixin BlackHover {
  &:hover {
    background-color: $bg-button-hover;
  }
}

@mixin DGreyBG {
  background-color: $bg-main-darkgrey;
  color: $text-white;
}

@mixin PurpleBG {
  background-color: $bg-main-purple;
  color: $text-white;
}

@mixin PurpleHover {
  &:hover {
    background-color: $bg-main-black;
  }
}

@mixin RedBG {
  background-color: $bg-red;
  color: $text-white;
}

@mixin RedHover {
  &:hover {
    background-color: $bg-red-hover;
  }
}

@mixin BlueBG {
  background-color: $bg-main-blue;
  color: $text-white;
}

@mixin LBlueBG {
  background-color: $bg-main-lightblue;
  color: $text-black;
}
