@import '../../assets/styles/mixins';

.headerWrap {
  @include DefaultBG;

  .content {
    padding: 40px 0 30px 0;
  }
  
  .childs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 57px;
  }
}


.rightCol {
  display: inherit;
  align-items: inherit;
}

.count {
  color: $text-red;
  font-weight: bold;
  margin-right: 30px;
}

@include for-tablet {
}

@include for-phone {
  .assigns {
    display: none;
  }
}
