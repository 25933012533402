// author: Victor K.
// bg colors
$bg-main-black: #000;
$bg-main-white: #FFF;
$bg-main-purple: #FB07A7;
$bg-main-blue: #2A5DBA;
$bg-main-lightblue: #D3E7F2;
$bg-main-darkgrey: #0D0D0D;
$bg-input: #F7F7F7;
$bg-input-hover: #ECECEC;
$bg-red: #E85440;
$bg-red-hover: #C23E2D;
$bg-button-hover: #303030;
// bg colors

// elems colors
$elem-black: #000;
$elem-white: #FFF;
$elem-dgrey: #CCCCCC;
$elem-lgrey: #F2F2F2; 
$elem-blue: #2A5DBA;
$elem-purple: #FB07A7;
$elem-border: #E3E3E3;
$elem-red: #E85440;
// $elem-red: #FA3B2E;

// text colors
$text-black: #000;
$text-white: #FFF;
$text-purple: #FB07A7;
$text-grey: #A4A4A4;
$text-red: #E85440;
$text-dgrey: #7C7C7C;
$text-d2grey: #505050;
$text-in-form: #ADADAD;
$text-error: #FF0000;
