@import '../../assets/styles/mixins.scss';

.expertJuryWrap {
  @include DefaultBG;

  .content {
    // padding-top: 0;
  }
  
  .childs {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    margin-top: 50px;

    h3 {
      margin: 0 0 30px 0;
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
      line-height: 36px;
      color: #A4A4A4;

      @include for-phone {
        font-size: 24px;
        line-height: 29px;
      }
    }
  }
}


.profGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 0px;

  @include for-tablet {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  }
}

.profItem {
  width: 100%;
  height: 400px;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  padding: 30px 40px;
  box-sizing: border-box;
  text-align: initial;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-radius: 20px;
  cursor: pointer;
  
  // PROF_BACK_ID[prof].inner_id
  &.item_1 {
    background-color: #FAE8E6;
    background-image: url('../../assets/images/bg/topics/gid-transl.svg');
  }
  &.item_2 {
    background-color: #E6EDFA;
    background-image: url('../../assets/images/bg/topics/guide.svg');
  }
  &.item_3 {
    background-color: #F4ECFA;
    background-image: url('../../assets/images/bg/topics/hotel-admin.svg');
  }
  &.item_4 {
    background-color: #F1FAE6;
    background-image: url('../../assets/images/bg/topics/tour-manager.svg');
  }

  &.selected {
    border: 2px solid black;
  }

  @include for-desktop-sm {
    padding: 20px 25px;
    height: 350px;
  }

  @include for-tablet {
    padding: 30px;
    height: 420px;
  }

  @include for-phone {
    padding: 20px;
    height: 230px;
    font-size: 18px;
    line-height: 22px;
  }
}

.resultsModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);
  display: flex;
  justify-content: center;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  
  @include for-phone {
    padding: 0px;
  }
  
  .modalView {
    width: 100%;
    max-width: 1200px;
    padding: 50px;
    background-color: white;
    height: fit-content;
    border-radius: 10px;
    box-sizing: border-box;
    position: relative;

    @include for-tablet {
      padding: 20px;
    }

    @include for-phone {
      padding: 10px;
      border-radius: 0px;
    }

    h3 {
      margin: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 48px;
      width: 90%;
      color: #000000;
      margin-bottom: 60px;

      @include for-tablet {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 44px;
      }

      @include for-phone {
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 36px;
      }
    }

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 20px;
      height: 20px;
      padding: 20px;
      cursor: pointer;
      background-image: url(../../assets/images/close-cross.svg);
      background-size: 20px 20px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .resGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 40px;

    @include for-tablet {
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 20px;
      grid-row-gap: 40px;
    }

    @include for-phone {
      padding: 10px;
      border-radius: 0px;
    }

    .resItem {
      img {
        height: 72%;
        display: block;
        width: 100%;
        object-fit: cover;
        overflow: hidden;
        margin-bottom: 20px;

        @include for-tablet {
          height: 72%;
        }
    
        @include for-phone {
          height: 70%;
        }
      }
      span {
        display: block;
        width: 80%;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
      }
    }
  }
}

.img {
  width: 200px;
}