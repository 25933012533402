.partner {
  display: block;
  height: 70px;
}

.partner_image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: left;
}