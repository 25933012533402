@import '../../assets/styles/mixins.scss';

.expertJuryWrap {
  @include DefaultBG;

  .content {
    padding-top: 0;
  }
  
  .childs {}
}

.switcher {
  border: 1px solid #CCCCCC;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  margin-top: 50px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;

  .switch {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    border-radius: 5px;
    min-height: 128px;
    padding: 10px 30px;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    cursor: pointer;

    &:hover {
      background-color: #F2F2F2;
    }

    &.active {
      @include RedBG;
      &:hover {
        @include RedBG;
      }
    }
  }
}

.expertCat {
  background: #F2F2F2;
  border-radius: 12px;
  margin-bottom: 20px;
  
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 39px;
    cursor: pointer;
    padding: 30px;

    &::after {
      margin-left: 30px;
      display: block;
      content: '';
      width: 26px;
      height: 26px;
      background-image: url('../../assets/images/big-arrow.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: .2s transform;
    }

    &.opened::after {
      transform: rotate(90deg);
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1em;
  grid-row-gap: 2em;
  padding: 10px 30px 30px;
}

@include for-desktop-sm {
  .switcher {
    margin-bottom: 22px;
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include for-tablet {
  .switcher {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20px;
  }
  .expertCat {
    .title {
      padding: 20px;
      font-size: 26px;
      line-height: 31px;
    }
  }
  .grid {
    grid-template-columns: repeat(2, 1fr);
    padding: 20px;
  }
}

@include for-phone {
  .expertCat {
    .title {
      font-size: 20px;
      line-height: 24px;
      padding: 16px;
    }
  }
  .grid {
    grid-template-columns: repeat(1, 1fr);
    padding: 5px 16px 16px;
  }
}
