@import '../../assets/styles/colors';
@import '../../assets/styles/mixins';

.timerWrapper {}

.timerTitle,
.timeEnded {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
}

.timer {
  display: flex;
  justify-content: space-between;
}

.timeValue {
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 61px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.fixedWidth {
    width: 25%;
  }
}

.timeName {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

@include for-tablet {
  .timerTitle,
  .timeEnded {
    font-size: 14px;
    line-height: 17px;
  }
  .timeValue {
    font-size: 40px;
    line-height: 49px;
  }
  .timeName {
    font-size: 12px;
    line-height: 15px;
  }
}

@include for-phone {}