// author: Victor K.

.checkboxSwitcher {
  display: flex;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  text-align: left;

  // &.error {
  //   border-left: 1px solid red;
  // }
}

.psCheckbox {
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  border: 1px solid black;
  cursor: pointer;
  margin-right: 15px;
  
  &.checked {
    background-color: black;
    background-image: url(../../assets/images/checkbox-check.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  &.error {
    border-color: red;
  }
}

.text {
  max-width: 400px;
}