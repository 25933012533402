// author: Victor K.
@import '../../assets/styles/mixins';

.engageWrap {
  @include DefaultBG;

  @include mainBackground {
    background-image: url('../../assets/images/bg/site/1_people.svg');
    background-position-y: bottom;
    background-position-x: calc(50% + 306px);
    background-size: 956px auto;
  };
  .content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .childs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-bottom: 95px;
  }
}

.bigLogo {
  width: 70vmin;
  margin-bottom: 58px;
}

.tags {
  color: $text-grey;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;

  .tag {
    margin-bottom: 10px;
  }
}

.linksWrap {
  @include BlueBG;
  border-bottom: 1px solid white;
  .content {
    padding: 30px 0;
  }
  .childs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.partners {
  display: flex;
  flex-direction: row;
  
  .partnerItm {
    height: 60px;
    margin-right: 40px;
  }
}

@include for-desktop-sm {
  .engageWrap {
    background-size: 725px auto;
    background-position-x: calc(50% + 150px);
  }
  .bigLogo {
    width: 42vmin;
    margin-bottom: 30px;
  }
}

@include for-tablet {
  .engageWrap {
    background-size: 423px auto;
    background-position-x: calc(50% + 120px);
    .childs {
      padding-bottom: 45px;
    }
  }
  .bigLogo {
    width: 50vmin;
    margin-bottom: 10px;
  }
  .tags {
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
  }
  .partners .partnerItm {
    height: 40px;
    margin-right: 30px;
  }
}

@include for-phone {
  .engageWrap {
    background-size: 80% auto;
    background-position-x: calc(50%);
    background-position-y: center;
    .childs {
      align-items: center;
      flex-direction: column;
      padding-bottom: 53px;
    }
  }
  .bigLogo {
    width: 85vmin;
    margin: 20px auto 210px;
  }
  .tags {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 12px;
  }
  .linksWrap {
    .content {
      padding: 20px 0;
    }
    .childs {
      display: block;
    }
  }
  .partners {
    justify-content: space-between;
    margin-bottom: 20px;
    .partnerItm {
      height: 40px;
      margin-right: 0px;
    }
  }
}