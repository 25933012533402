@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/colors.scss';

.selector {
  position: relative;
  text-align: left;

  .active {
    .pseudoInput {
      background-color: $bg-input-hover;
      color: $text-black;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.08);
      &::before {
        transform: scaleY(-1);
      }
    }
  }
}

.pseudoInput {
  @include GeneralInput;
  padding: 18px 42px 18px 19px;
  // border: none;
  color: #a7a7a7;
  cursor: pointer;
    
  &.selected {
    color: $text-dgrey;
  }

  &.error {
    border-color: red;
  }

  &::before {
    position: absolute;
    width: 14px;
    height: 9px;
    content: '';
    top: 24px;
    right: 20px;
    background-image: url(../../assets/images/select-chevron.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover {
    background-color: $bg-input-hover;
  }
}

.options {
  position: absolute;
  z-index: 10;
  top: 100%;
  width: 100%;
  padding: 10px 0;
  background-color: white;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.08);
}

.option {
  font-weight: 500;
  line-height: 130%;
  padding: 10px 20px;

  &:hover {
    background-color: $bg-input-hover;
    cursor: pointer;
  }
}