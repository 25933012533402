// author: Victor K.
@import '../../assets/styles/mixins.scss';

.timetableWrap {
  @include BlueBG;

  .content {
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    padding-top: 1px;
    padding-bottom: 140px;
  
    p {
      margin-top: 0;
    }
  }
  
  .childs {
    h3 {
      font-weight: 600;
      font-size: 26px;
      line-height: 100%;
      margin: 0 0 30px;
      max-width: 300px;
    }
  }
}

.timeLine {
  display: flex;
  justify-content: space-between;
  padding-top: 9px;

  background-image: url('../../assets/images/timeLineArrow.svg');
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: right;
  background-size: auto 38px;
}

.timeItem {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  width: 23%;
  max-width: 270px;

  &:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 5px solid white;
    background-color: $bg-main-blue;
    box-sizing: border-box;
    border-radius: 50%;
    margin-bottom: 30px;
  }

  &:hover::before {
    background-color: white;
  }
}

.itemRange {
  font-weight: 600;
  font-size: 36px;
  line-height: 100%;
  margin-bottom: 15px;
}


@include for-desktop-sm {
  .timeItem {
    font-size: 14px;
  }
  .itemRange {
    font-size: 26px;
  }
}

@include for-tablet {
  .timetableWrap {
    .content {
      padding-top: 80px;
      padding-bottom: 110px;
    }
    .childs h3 {
      max-width: 100%;
    }
  }
  .timeLine {
    background: none;
    flex-wrap: wrap;
  }
  .timeItem {
    &::before {display: none;}
    width: 50%;
    margin-bottom: 35px;
  }
  .itemRange {
    margin-bottom: 5px;
  }
}

@include for-phone {
  .timetableWrap {
    .content {
      padding-top: 60px;
      padding-bottom: 80px;
    }
  }
  .timeItem {
    width: 100%;
    margin-bottom: 30px;
  }
}