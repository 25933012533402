@import '../../assets/styles/mixins.scss';

.partnersWrap {
  .content {
    padding-top: 0;
  }
  .childs {}
}

.grid {
  padding-top: 10px;
  display: flex;
  grid-column-gap: 120px;
  grid-row-gap: 30px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: baseline;
  align-content: center;
  justify-content: center;
}

@include for-tablet {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@include for-phone {
  .grid {
    grid-template-columns: repeat(1, 1fr);
  }
}