@import '../../assets/styles/mixins.scss';

.formWrapper {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.formOverlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background-color: rgba(0,0,0,.5);
  z-index: -1;
}

.formContent {
  position: relative;
  width: 625px;
  margin-top: 50px;
  padding: 60px 50px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 10px;
}

.formCloseCross {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  color: black;
  overflow: hidden;
  background-image: url(../../assets/images/close-cross.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.formTitle {
  font-size: 30px;
  margin: 0 0 30px 0;
}

.fullWidth {
  margin-top: 50px;
  width: 100%;
}

@include for-tablet {
  .formContent {
    padding: 60px 38px;
  }
}

@include for-phone {
  .formContent {
    margin-top: 0px;
    padding: 60px 11px;
    border-radius: 0px;
  }
  .formTitle {
    font-size: 24px;
    line-height: 29px;
  }
}
