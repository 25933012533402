.radioSwitcher {
  text-align: left;
  margin-bottom: 20px;

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    margin: 0 0 15px 0;
  }

  .answer {
    display: flex;
  }

  .radio {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    cursor: pointer;
    margin-right: 40px;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      width: 20px;
      height: 20px;
      box-sizing: border-box;
      border: 1px solid black;
      border-radius: 50%;
      margin-right: 10px;
    }

    &.active {
      position: relative;
      &:after {
        position: absolute;
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        left: 4px;
        background-color: black;
        border-radius: 50%;
        margin-right: 10px;
      } 
    }
  }
}