// author: Victor K.
@import '../../assets/styles/mixins';

.loader {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  &::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border-top: 5px solid rgba(0,255,0,0);
    border-right: 5px solid $bg-main-blue;
    border-bottom: 5px solid rgba(0,255,0,0);
    border-left: 5px solid $bg-main-blue;
    animation: Rotating .35s cubic-bezier(.9, 0.1, 0.4, .9) infinite;
  }

  &.white {
    &::after {
      border-right: 5px solid white;
      border-left: 5px solid white;
    } 
  }
}

@keyframes Rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}