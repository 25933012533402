// author: Victor K.
@import '../../assets/styles/mixins';
.links {
  display: flex;
  justify-content: center;
}

.socialLink {
  display: block;
  width: 30px;
  height: 30px;
  margin-left: 30px;
  background-image: url('../../assets/images/socials.svg');
  background-position-y: 30px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  color: rgba(0,0,0,0);
  background-color: darkgray;

  &.inst {
    background-color: initial;
    background-position-y: 0;
    background-position-x: 50%;
  }
  &.vk {
    background-color: initial;
    background-position-y: 0;
    background-position-x: 100%;
  }
  &.fb {
    background-color: initial;
    background-position-y: 0;
    background-position-x: 0;
  }
}

@include for-phone {
  .links {
    justify-content: left;
  }
  .socialLink {
    margin-left: 0px;
    margin-right: 30px;
  }
}