@import '../../assets/styles/colors.scss';

.compass {
  display: block;
  width: 100%;
  max-width: 520px;
  height: auto;
  pointer-events: none;
  object-fit: cover;
  position: relative;
}

.invert {
  .compassItem path {
    fill: $elem-white;
  }
  .compassArrow path {
    fill: $elem-purple;
  }
}

.compassItem {
  width: 100%;
  height: auto;
}

.compassArrow {
  position: absolute;
  top: -10.7%;
  right: -10.7%;
  width: 121.4%;
  height: auto;
}

.compassText {
  position: absolute;
  left: -11.9%;
  bottom: -11.9%;
  width: 123.8%;
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .compassArrow {
    animation: Compass-arrow-rotate 3s cubic-bezier(.8, 0.4, 0.2, .8) infinite alternate;
  }
}

@keyframes Compass-arrow-rotate {
  from {
    transform: rotate(-9deg);
  }
  to {
    transform: rotate(9deg);
  }
}