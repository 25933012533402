// @import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.uploader {
   position: relative;
   display: block;
  //  width: 100px;
  //  height: 100px;
   overflow: hidden;

   &:hover span {
      text-decoration: underline;
   }
}

.uploadInput {
   position: absolute;
   display: block;
   width: 100px;
   height: 100px;
   padding: 20px 0 0 0;
   left: 0;
   top: 0;
   opacity: 0;
   cursor: pointer;
}

.fileName {
   font-style: normal;
   font-weight: normal;
   font-size: 16px;
   line-height: 100%;
   // color: #и;
   padding: 20px;
   width: 100%;
}

.fileSend {
   width: fit-content;
   font-style: normal;
   font-weight: 600;
   font-size: 12px;
   line-height: 15px;
   letter-spacing: 0.04em;
   text-transform: uppercase;
   color: white;
   padding: 17px 30px;
   background-color: black;
   margin-left: auto;
   margin-top: 30px;
}

.customModalOverlay {
   background-color: rgba(14,14,14,0.6) !important;
   
   .customTitle {
     font-style: normal;
     font-weight: normal;
     font-size: 26px;
     line-height: 41px;
     text-align: center;
     margin-left: auto;
     margin-right: auto;
     margin-bottom: 30px;
     padding: 0 10px;
   }
   
   .customClose {
     top: 10px;
     right: 10px;
     width: 55px;
     height: 55px;
   }
 
   .customModal {
     position: relative;
     margin-top: 40px;
     padding: 50px;
     max-width: 600px;
     background-color: #fff;
     .modalContent {
     }
   }
 
   .form {
     max-width: 420px;
     margin: 0 auto;
   }
 
   input,
   textarea {
     font-style: normal;
     font-weight: normal;
     font-size: 16px;
     line-height: 150%;
     background-color: #fff;
   }
 }
 
 .input {
   width: 100%;
   margin-bottom: 20px;
 }
 
 .link {
   text-decoration: underline;
  //  color: $color-primary;
 
   &.linkBig {
     font-size: 14px;
     font-weight: 600;
   }
 }
 
 .textarea {
   width: 100%;
   margin-bottom: 40px;
 }
 
 .checkbox {
   margin-bottom: 40px;
 }
 
 .buttonSubmit {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding: 17px 30px;
   margin-top: 60px;
   width: 100%;
   height: 50px;
   background: #141414;
   color: white;
   font-weight: 600;
   font-size: 12px;
   line-height: 15px;
   letter-spacing: 0.04em;
   text-transform: uppercase;
 }
 
 .customOpener {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   width: 193px;
   height: 50px;
   background: #141414;
   font-style: normal;
   font-weight: 600;
   font-size: 12px;
   line-height: 15px;
   margin: 0px auto 0 auto;
   letter-spacing: 0.04em;
   text-transform: uppercase;
   cursor: pointer;
   color: #FFFFFF;
   padding: 0;
 }
 
 @media screen and (max-width: 1040px) {
   .customModalOverlay {
     .customModal {
       margin: 20px;
     }
   }
 }
 
 @media screen and (max-width: 480px) {
   .customModalOverlay {
     background-color: #fff !important;
 
     .customTitle {
       font-size: 22px;
       line-height: 27px;
     }
 
     .customClose{
       top: 5px;
       right: -20px;
     }
 
     .customModal {
       margin: 10px;
     }
   }
 }