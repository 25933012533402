@import '../../assets/styles/mixins.scss';

.blockWrap {
  padding: 0 20px;
}

.blockContent {
  @include MainBlocksStyle;
  
  &.inlineFlow {
    padding: 10px 0 150px;

    .blockTitle {
      margin: 0;
    }

    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    align-items: flex-start;
  }
}

.blockTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 80px;
  line-height: 100%;
  margin: 0 0 40px;
  @include BlockSelection;
}

.blockChilds {
  @include BlockSelection;
}

@include for-desktop-sm {
  .blockTitle { margin: 0 0 50px }
}

@include for-tablet {
  .blockWrap {
    height: auto !important;
  }

  .blockTitle {
    margin: 0 0 40px;
    font-size: 50px;
  }
  
  .blockContent{
    &.inlineFlow {
      .blockTitle {
        margin: 0 0 30px 0;
      }
      display: block;
      padding: 0px 0 80px;
    }
  }
}

@include for-phone {
  .blockWrap {
    padding: 0 10px;
  }

  .blockTitle {
    font-size: 40px;
    line-height: 39px;
    margin: 0 0 30px;
  }

  .blockChilds {
    font-size: 15px;
    line-height: 130%;
  }
}