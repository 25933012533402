@import '../../assets/styles/mixins.scss';

.aboutWrap {
  @include BlueBG;

  @include mainBackground {
    background-image: url('../../assets/images/bg/site/2_about.svg');
    background-position-y: bottom;
    background-position-x: calc(50% + 350px);
    background-size: 671px auto;
  };
  
  .content {
    padding: 140px 0 90px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    color: white;
    margin-right: auto;

    @include for-desktop-sm {
      padding: 100px 0;
    }
  
    @include for-phone {
      padding: 60px 0;
    }
  }
  
  .childs {
    max-width: 580px;
    width: 50%;
  }
}

.HTMLText {
  font-family: 'Inter' !important; 
  color: white !important;
  * {font-family: 'Inter' !important; }
  p { margin-top: 0; }
}

@media screen and (max-width: 1200px) {
  .aboutWrap {
    background-position-x: calc(50% + 400px);
  }
}

@include for-tablet {
  .aboutWrap {
    background-position-x: calc(50%);
    background-size: 90% auto;
    .content {
      padding-bottom: 100%;
    }
    .childs {
      max-width: 100%;
      width: 100%;
    }
  }
  .HTMLText {
    font-size: 14px;
    line-height: 140%;
  }
}

@include for-phone {
  .aboutWrap {
    background-position-x: calc(50%);
    background-size: 80% auto;
    .content {
      padding-bottom: 85%;
    }
    .childs {
      max-width: 100%;
      width: 100%;
    }
  }
}