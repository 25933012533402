@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.contactEmail {
  display: inline-block;
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 44px;
  line-height: 53px;
  
  @include UnblockSelection;
  @include SeparatorRight;
}
