@import '../../assets/styles/mixins.scss';

.attentionWrap {
  &:before {
    top: 0;
    left: -20px;
    display: block;
    margin-bottom: -300px;
    position: relative;
    z-index: -1;
    content: "";
    width: 120%;
    height: 300px;
    @include BlueBG;
  }

  .content {
    max-width: 1400px;
    width: 100%;
    padding: 0 0 150px;
  }
  
  .childs {
    width: 100%;
    background: white;
    box-shadow: 0px 16px 30px rgba(0, 0, 0, 0.09);
    border-radius: 40px;
    box-sizing: border-box;
    padding: 100px;
    background-image: url('../../assets/images/bg/site/3_apply.svg');
    background-repeat: no-repeat;
    background-position: calc(60% + 300px) bottom;
    // background-size: 40vw;
  }
}

.applyTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 70px;
  line-height: 100%;
  color: $text-red;
  margin-bottom: 50px;
  max-width: 800px;
}

.hiddenTitle {
  position: absolute;
  opacity: 0;
}

@include for-desktop-sm {
  .attentionWrap {
    .childs {
      padding: 60px;
      background-position: calc(50% + 280px) bottom;
      background-size: 395px auto;
    }
  }
  .applyTitle {
    width: 70%;
    font-size: 50px;
  }
}

@include for-tablet {
  .attentionWrap {
    .content {
      padding-bottom: 120px;
    }
    .childs {
      padding: 40px;
      background-position: 10% 60%;
      background-size: 60% auto;
    }
  }
  .applyTitle {
    width: 100%;
    margin-bottom: 55%;
  }
}

@include for-phone {
  .attentionWrap {
    text-align: center;
    .childs {
      padding: 15px;
      background-position: 50% 50%;
      background-size: 90% auto;
    }
  }
  .applyTitle {
    font-size: 24px;
    margin-bottom: 80%;
  }
}