@import '../../assets/styles/mixins.scss';

.cubicButton {
  @include RedBG;
  @include RedHover;
  display: inline-flex;
  padding: 19px 30px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  box-sizing: border-box;
  
  &.accent {
    @include DefaultBG;
    @include DefaultHover;
  }
  &.invert {
    @include BlackBG;
    @include BlackHover;
  }

  &.withArrow {
    &:after {
      content: '';
      width: 14px;
      height: 14px;
      margin-left: 10px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url('../../assets/images/arrow-black.svg');
    }
    
    &:hover::after {
      background-image: url('../../assets/images/arrow-white.svg');
    }

    &.accent:after,
    &.invert:after {
      background-image: url('../../assets/images/arrow-white.svg');
    }
  }
}