@import '../../assets/styles/mixins';

.topLink {
  font-weight: bold;
  margin: 0 15px 0 0;
}

.burger {
  display: none;
}

@include for-tablet {
  .burger {
    display: block;
    width: 57px;
    height: 57px;
    background-color: $elem-red;
    background-image: url(../../assets/images/burger.svg);
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    border-radius: 4px;
  
    &.opened {
      position: fixed;
      top: 40px;
      z-index: 20;
      background-image: url(../../assets/images/close-cross2.svg);
      background-color: $elem-red;
    }
  }

  .topMenu {
    display: none;
    position: fixed;
    padding-left: 20px;
    &.opened {
      z-index: 10;
      background-color: white;
      top: 0;
      left: 0;
      padding-top: 150px;
      width: 100%;
      max-width: 500px;
      height: 100%;
      display: block;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.5);
      }
    }
  }
  .topLink {
    display: flex;
    flex-direction: column;
    font-weight: 600;
    font-size: 40px;
    margin-bottom: 30px;
  }
}

@include for-phone {
  .topLink {
    font-size: 30px;
    margin-bottom: 25px;
  }
}
