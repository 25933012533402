.appLogo {
  display: block;
  height: 30vmin;
  width: 30vmin;
  pointer-events: none;
  object-fit: cover;
}

@media (prefers-reduced-motion: no-preference) {
  .appLogo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
