@import '../../assets/styles/colors';
@import '../../assets/styles/fonts';
@import '../../assets/styles/mixins';

.HTMLContent {
  color: inherit;
  // color: $text-black;
  font-size: 18px;
  line-height: 140%;

  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 30px 0 15px 0;
  }

  ol, ul {
    padding: 0 0 0 30px;
    margin: 0;
    li {
      list-style-type: inherit;
      margin-bottom: 10px;
    }
  }
}

@include for-phone {
  .HTMLContent {
    font-size: 16px;
  }
}