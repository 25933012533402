@import '../../assets/styles/mixins.scss';
@import '../../assets/styles/colors.scss';

.cfsAuthor {
  color: white;
  margin-left: 10px;
  text-align: right;
  @include for-phone {
    margin-top: 10px;
    margin-left: 0px;
    text-align: initial;
  }
}

.cfsExtLink {
  color: white;
  text-decoration: underline;
}
