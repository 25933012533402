@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';

.expertItem {
  display: flex;
  flex-direction: column;
}

.expertItem_name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.expertItem_pos {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 130%;
  color: #A4A4A4;
}

@include for-tablet {
  // .expertItem {
  //   flex-direction: column;
  // }

  // .expertItem_name {
  //   .full {display: none;}
  //   .mobile {display: initial;}
  // }
}

@include for-phone {
  // .expertItem {
  //   flex-direction: column;
  // }
}