@import '../../assets/styles/mixins.scss';

.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin: 30px 0;
}

.test {
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  border-bottom: 1px solid #CCCCCC;
  margin-bottom: 30px;
  padding-bottom: 10px;
  
  .testLink {
    margin-top: 10px;
    padding-bottom: 20px;
    a {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      color: #000000;
    }
  }
}

.link {
  float: left;
  color: $elem-black;
  font-weight: normal;
  font-size: 16px;
  line-height: 100%;
  text-decoration: underline;
}

.profGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;
}

.profItem {
  width: 100%;
  height: 220px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding: 20px;
  box-sizing: border-box;
  text-align: initial;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: bottom center;
  border-radius: 20px;
  cursor: pointer;
  
  // PROF_BACK_ID[prof].inner_id
  &.item_1 {
    background-color: #FAE8E6;
    background-image: url('../../assets/images/bg/topics/gid-transl.svg');
  }
  &.item_2 {
    background-color: #E6EDFA;
    background-image: url('../../assets/images/bg/topics/guide.svg');
  }
  &.item_3 {
    background-color: #F4ECFA;
    background-image: url('../../assets/images/bg/topics/hotel-admin.svg');
  }
  &.item_4 {
    background-color: #F1FAE6;
    background-image: url('../../assets/images/bg/topics/tour-manager.svg');
  }

  &.selected {
    border: 2px solid black;
  }
}

@include for-phone {
  .subtitle {
    font-size: 18px;
    line-height: 22px;
  }

  .profGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .profItem {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 35px 20px;
    font-size: 16px;
    line-height: 19px;
    height: auto;
    background-image: none !important;
  }
}